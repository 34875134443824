<template>
  <div class="content">
    <PageHeader title="Horário de funcionamento" />
    <div class="page-content container-fluid">
      <div v-if="loading" class="qt-block-ui"></div>
      <div class="card">
        <div class="card-header">Expediente</div>
        <div class="card-body block-el">
          <template v-for="(item, i) in weekdays">
            <div class="row weekdays" :key="i">
              <div class="col-7 col-md-3">
                <toggle-button
                  v-model="form[item.key]"
                  class="mt5"
                  :sync="true"/>
                <span>{{item.label}}</span>
              </div>
              <template v-if="form[item.key]">
                <div class="col-6 col-md-3">
                  <!-- <input type="time" class="form-control" v-model="form[`begin_hour_${item.key}`]"> -->
                  <date-picker
                    v-model="form[`begin_hour_${item.key}`]"
                    :config="options"
                    class="form-control input-date"
                    placeholder="--:--"/>
                </div>
                <div class="col-6 col-md-3">
                  <!-- <input type="time" class="form-control" v-model="form[`end_hour_${item.key}`]"> -->
                  <date-picker
                    v-model="form[`end_hour_${item.key}`]"
                    :config="options"
                    class="form-control input-date"
                    placeholder="--:--"/>
                </div>
              </template>
              <div class="col-3 col-md-6 closed" v-else>Fechado</div>
            </div>
          </template>
          <hr>
          <div class="py-2">
            <div class="form-group row">
              <label for="inputEmail3" class="col-12 col-md-2 col-form-label">
                Departamentos
              </label>
              <div class="col-12 col-md-10">
                <select-input
                  label="name"
                  v-model="form.departments"
                  :reduce="(field) => `${field.id};${field.name}`"
                  :options="departments"
                  :clearable="true"
                  :multiple="noGeneral" />
              </div>
            </div>
          </div>
          <hr>
          <div class="py-2">
            <h3>Opções</h3>
            <div class="row">
              <div class="col-lg-6">
                <p>Não finalizar atendimentos abertos após o horário</p>
                <toggle-button
                  v-model="form.time_end_but_continue"
                  class="mt5"
                  :sync="true"/>
                <!--
                <input
                  class="tgl tgl-light tgl-primary"
                  type="checkbox"
                  id="live-time_end_but_continue"
                  v-model="form.time_end_but_continue">
                <label class="tgl-btn" for="live-time_end_but_continue"></label>
                -->
              </div>
              <div class="col-lg-6">
                <p>Continuar a atender clientes que já estão na fila após o horário</p>
                <toggle-button
                  v-model="form.time_end_but_work_queue"
                  class="mt5"
                  :sync="true"/>
                <!--
                <input
                  class="tgl tgl-light tgl-primary"
                  type="checkbox"
                  id="live-time_end_but_work_queue"
                  v-model="form.time_end_but_work_queue">
                <label class="tgl-btn" for="live-time_end_but_work_queue"></label>
                -->
              </div>
            </div>

            <!-- <template v-for="(product, i) in new Array(12)">
              <div class="custom-control custom-checkbox" :key="i">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  :id="'product' + i"
                />
                <label class="custom-control-label" :for="'product' + i"></label>
              </div>
            </template> -->
          </div>
          <hr>
          <div class="py-2">
            <label class="form-label">Mensagem que será exibida aos usuários que acessam foram do expediente</label>
            <textarea class="form-control" v-model="form.message"></textarea>
          </div>
        </div>
        <div class="card-footer d-flex justify-content-end bg-light">
          <button class="btn btn-primary" @click="save">Salvar</button>
        </div>
      </div>
      <!-- <pre>{{form}}</pre> -->
      <!-- <div v-if="loading" class="qt-block-ui"></div> -->
      <div class="card" v-if="false">
        <div class="card-header">Expediente</div>
        <div class="card-body block-el">
          <!-- <div>
            <label class="control-label text-left">Titulo</label>
            <input type="text" class="form-control" v-model="form.name">
          </div> -->
          <div class="row">
            <div class="col-6">
              <label class="control-label text-left">Horário de início</label>
              <input type="time" class="form-control" v-model="form.begin_hour">
            </div>
            <div class="col-6">
              <label class="control-label text-left">Horário de término</label>
              <input type="time" class="form-control" v-model="form.end_hour">
            </div>
          </div>
          <div class="py-2">
            <label class="form-label">Departamentos</label>
            <select-input
              label="name"
              v-model="form.departments"
              :reduce="(field) => `${field.id};${field.name}`"
              :options="departments"
              :clearable="true"
              :multiple="noGeneral"
            />
          </div>
          <hr>
          <div class="py-2">
            <h3>Dias da semana</h3>

            <div class="flex-container">
              <div class="p-3">
                <p>Domingo</p>
                <toggle-button
                  v-model="form.sunday"
                  class="mt5"
                  :sync="true"/>
                <!-- <input
                  class="tgl tgl-light tgl-primary"
                  type="checkbox"
                  id="live-sunday"
                  v-model="form.sunday">
                <label class="tgl-btn" for="live-sunday"></label> -->
              </div>
              <div class="p-3">
                <p>Segunda</p>
                <toggle-button
                  v-model="form.monday"
                  class="mt5"
                  :sync="true"/>
                <!-- <input
                  class="tgl tgl-light tgl-primary"
                  type="checkbox"
                  id="live-monday"
                  v-model="form.monday">
                <label class="tgl-btn" for="live-monday"></label> -->
              </div>
              <div class="p-3">
                <p>Terça</p>
                <toggle-button
                  v-model="form.tuesday"
                  class="mt5"
                  :sync="true"/>
                <!-- <input
                  class="tgl tgl-light tgl-primary"
                  type="checkbox"
                  id="live-tuesday"
                  v-model="form.tuesday">
                <label class="tgl-btn" for="live-tuesday"></label> -->
              </div>
              <div class="p-3">
                <p>Quarta</p>
                <toggle-button
                  v-model="form.wednesday"
                  class="mt5"
                  :sync="true"/>
                <!-- <input
                  class="tgl tgl-light tgl-primary"
                  type="checkbox"
                  id="live-wednesday"
                  v-model="form.wednesday">
                <label class="tgl-btn" for="live-wednesday"></label> -->
              </div>
              <div class="p-3">
                <p>Quinta</p>
                <toggle-button
                  v-model="form.thursday"
                  class="mt5"
                  :sync="true"/>
                <!-- <input
                  class="tgl tgl-light tgl-primary"
                  type="checkbox"
                  id="live-thursday"
                  v-model="form.thursday">
                <label class="tgl-btn" for="live-thursday"></label> -->
              </div>
              <div class="p-3">
                <p>Sexta</p>
                <toggle-button
                  v-model="form.friday"
                  class="mt5"
                  :sync="true"/>
                <!-- <input
                  class="tgl tgl-light tgl-primary"
                  type="checkbox"
                  id="live-friday"
                  v-model="form.friday">
                <label class="tgl-btn" for="live-friday"></label> -->
              </div>
              <div class="p-3">
                <p>Sábado</p>
                <toggle-button
                  v-model="form.sartuday"
                  class="mt5"
                  :sync="true"/>
                <!-- <input
                  class="tgl tgl-light tgl-primary"
                  type="checkbox"
                  id="live-sartuday"
                  v-model="form.sartuday">
                <label class="tgl-btn" for="live-sartuday"></label> -->
              </div>
            </div>

            <!-- <template v-for="(product, i) in new Array(12)">
              <div class="custom-control custom-checkbox" :key="i">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  :id="'product' + i"
                />
                <label class="custom-control-label" :for="'product' + i"></label>
              </div>
            </template> -->
          </div>
          <hr>
          <div class="py-2">
            <h3>Opções</h3>
            <div class="row">
              <div class="col-lg-6">
                <p>Não finalizar atendimentos abertos após o horário</p>
                <toggle-button
                  v-model="form.time_end_but_continue"
                  class="mt5"
                  :sync="true"/>
                <!--
                <input
                  class="tgl tgl-light tgl-primary"
                  type="checkbox"
                  id="live-time_end_but_continue"
                  v-model="form.time_end_but_continue">
                <label class="tgl-btn" for="live-time_end_but_continue"></label>
                -->
              </div>
              <div class="col-lg-6">
                <p>Continuar a atender clientes que já estão na fila após o horário</p>
                <toggle-button
                  v-model="form.time_end_but_work_queue"
                  class="mt5"
                  :sync="true"/>
                <!--
                <input
                  class="tgl tgl-light tgl-primary"
                  type="checkbox"
                  id="live-time_end_but_work_queue"
                  v-model="form.time_end_but_work_queue">
                <label class="tgl-btn" for="live-time_end_but_work_queue"></label>
                -->
              </div>
            </div>

            <!-- <template v-for="(product, i) in new Array(12)">
              <div class="custom-control custom-checkbox" :key="i">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  :id="'product' + i"
                />
                <label class="custom-control-label" :for="'product' + i"></label>
              </div>
            </template> -->
          </div>
          <hr>
          <div class="py-2">
            <label class="form-label">Mensagem que será exibida aos usuários que acessam foram do expediente</label>
            <textarea class="form-control" v-model="form.message"></textarea>
          </div>
        </div>
        <div class="card-footer d-flex justify-content-end bg-light">
          <button class="btn btn-primary" @click="save">Salvar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Emojis from '@/components/Emojis.vue';
import PageHeader from '@/components/PageHeader.vue';
// import { ToggleButton } from 'vue-js-toggle-button';
import SelectInput from '@/components/SelectInput.vue';
import { ToggleButton } from 'vue-js-toggle-button';
import axios from '@/plugins/axios';

// Date
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import $ from 'jquery';
import moment from 'moment';

$.extend(true, $.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});

export default {
  name: 'CreateEditOpeningHour',
  components: {
    // InfiniteLoading,
    // Emojis,
    PageHeader,
    SelectInput,
    ToggleButton,
    datePicker,
  },
  props: ['id'],
  data() {
    return {
      loading: true,
      mainDepartment: null,
      formDepartmentsTimeout: 0,
      departments: [], // departamentos selecionaveis
      options: {
        format: 'HH:mm',
        useCurrent: true,
        showClear: true,
        showClose: true,
      },
      form: {
        sunday: false,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,

        begin_hour_sunday: '09:00',
        begin_hour_monday: '09:00',
        begin_hour_tuesday: '09:00',
        begin_hour_wednesday: '09:00',
        begin_hour_thursday: '09:00',
        begin_hour_friday: '09:00',
        begin_hour_saturday: '09:00',

        end_hour_sunday: '12:00',
        end_hour_monday: '18:00',
        end_hour_tuesday: '18:00',
        end_hour_wednesday: '18:00',
        end_hour_thursday: '18:00',
        end_hour_friday: '18:00',
        end_hour_saturday: '12:00',

        departments: [], // departamentos selecionados
        message: null,
        time_end_but_continue: false,
        time_end_but_work_queue: false,
      },
      // form: {
      //   begin_hour: null,
      //   departments: [], // departamentos selecionados
      //   end_hour: null,
      //   message: null,
      //   monday: false,
      //   saturday: false,
      //   sunday: false,
      //   friday: false,
      //   thursday: false,
      //   tuesday: false,
      //   wednesday: false,
      //   time_end_but_continue: false,
      //   time_end_but_work_queue: false,
      // },
    };
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
    noGeneral() {
      const depts = this.form.departments;
      const main = this.mainDepartment;

      if (depts === null || depts.length < 2 || !main) return true;

      const j = depts.length;

      for (let i = 0; i < j; i += 1) {
        if (depts[i] === main) {
          this.updateFormDepartments(depts[i]);
          return false;
        }
      }

      return true;
    },
    weekdays() {
      // No futuro traduzam apenas o campo LABEL
      return [
        { label: 'Domingo', key: 'sunday' },
        { label: 'Segunda', key: 'monday' },
        { label: 'Terça', key: 'tuesday' },
        { label: 'Quarta', key: 'wednesday' },
        { label: 'Quinta', key: 'thursday' },
        { label: 'Sexta', key: 'friday' },
        { label: 'Sábado', key: 'saturday' },
      ];
    },
  },
  mounted() {
    console.log('openinghours:mounted', this.id);
    this.fetch();
  },
  methods: {
    updateFormDepartments(department) {
      if (this.formDepartmentsTimeout) clearTimeout(this.formDepartmentsTimeout);

      this.formDepartmentsTimeout = setTimeout(() => {
        this.form.departments = [department];
      }, 10);
    },
    fetch() {
      axios().get('webchat/departments').then((response) => {
        this.loading = false;

        const departments = [];

        let main = null;

        response.data.forEach((department) => {
          console.log('OpeningHours', department);
          if (!main && department.name === 'Geral') main = `${department.id};${department.name}`;

          departments.push({
            id: department.id,
            name: department.name,
          });
        });

        this.departments = departments;

        if (main !== null) this.mainDepartment = main;
      }).catch((error) => {
        this.loading = false;
        console.error('OpeningHours', error);
      });
    },
    error(message) {
      this.$toast.show({
        title: 'Horário',
        content: message,
        type: 'danger',
      });
      return false;
    },
    validate() {
      // if (!this.form.begin_hour || !this.form.end_hour) return this.error('Defina o horário');
      const form = this.form;

      if (!form.departments.length) return this.error('Selecione pelo menos um departamento');

      if (!form.message) return this.error('Defina a mensagem alternativa');

      if (
        !form.monday && !form.saturday && !form.sunday &&
        !form.friday && !form.thursday && !form.tuesday &&
        !form.wednesday
      ) {
        return this.error('Selecione pelo menos um dia da semana');
      }

      const weekdays = this.weekdays;
      const j = weekdays.length;

      for (let i = 0; i < j; i += 1) {
        const weekday = weekdays[i];

        if (form[weekday.key] && (!form[`begin_hour_${weekday.key}`] || !form[`begin_hour_${weekday.key}`])) {
          return this.error(`Selecione o horário de abertura para ${weekday.label}`);
        }

        if (form[weekday.key] && (!form[`end_hour_${weekday.key}`] || !form[`end_hour_${weekday.key}`])) {
          return this.error(`Selecione o horário de fechamento para ${weekday.label}`);
        }
      }

      return true;
    },
    save() {
      if (!this.validate()) return;

      this.loading = true;

      axios().post('webchat/openinghours/create', this.form).then((response) => {
        this.loading = false;
        console.log('OpeningHours', response.data);
        this.$toast.show({
          title: 'Expediente',
          content: 'Horário(s) cadastrado(s) para o(s) departamento(s)',
          type: 'success',
        });
        this.$router.push('/live/configs/opening-hours/');
      }).catch((error) => {
        this.loading = false;
        const message = error?.response?.data?.message ?? error.message;
        console.error('OpeningHours', message);
        this.$toast.show({
          title: 'Expediente',
          content: message,
          type: 'danger',
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.weekdays > * {
  padding: .5rem;
  min-height: 54px;

  & > span {
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 .5rem .5rem;
  }

  &.closed, &:first-child {
    padding-top: .9rem;
  }
}

.flex-container {
  display: flex;
  flex-direction: row;

  & > div {
    flex: 14.28%;
  }

  @media (max-width: 800px) {
    flex-direction: column;
  }
}

textarea.form-control {
  min-height: 180px;
}
</style>
